import React, { useState } from "react";
import {
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  SelectChangeEvent,
  Grid,
  Box,
} from "@mui/material";
import TableOne from "./components/TableOne";
import ChartOne from "./components/ChartOne";
import TableTwo from "./components/TableTwo";
import TableThree from "./components/TableThree";
import employeeList from "./employeeList";

interface EmployeesProps {
  highlighting: boolean;
  dateRange: [Date | undefined, Date | undefined];
  currentArea: string;
  currentSubArea: string | null;
  heatMapEnabled: boolean
  setHeatMapEnabled: Function
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 5 + ITEM_PADDING_TOP, // shows 5 items at a time
    },
  },
};

function Employees({ highlighting, dateRange, currentArea,currentSubArea,heatMapEnabled,setHeatMapEnabled }: EmployeesProps) {
  const [selectedEmployee, setSelectedEmployee] = useState<string>("Trent Brown");

  const handleEmployeeChange = (event: SelectChangeEvent<string>) => {
    setSelectedEmployee(event.target.value);
    console.log(event.target.value)
  };

  const handleHeatMapToggle = (event: React.ChangeEvent<HTMLInputElement>) => {
    setHeatMapEnabled(event.target.checked);
  };

  return (
    <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" sx={{ padding: 3 }}>
      <Box sx={{ mt: 4, width:'100%', mb:2 }}>
      <TableOne highlighting={highlighting}
          dateRange={dateRange}
          currentArea={currentArea}
          selectedEmployee={selectedEmployee}
          currentSubArea={currentSubArea} />
      </Box>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={6} md={4}>
          <FormControl fullWidth variant="outlined">
            <InputLabel>Select Employee</InputLabel>
            <Select
              value={selectedEmployee}
              onChange={handleEmployeeChange}
              label="Select Employee"
              MenuProps={MenuProps}
            >
              {employeeList.map((employee, index) => (
                <MenuItem key={index} value={employee}>
                  {employee}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={6} md={8}>
          {/* Temporarily removing heat map functionality until it is fully built out */}
        {/* <FormGroup>
      <FormControlLabel control={<Switch checked={heatMapEnabled} onChange={handleHeatMapToggle} />} label="Heat Map" />
    </FormGroup> */}

        </Grid>
      </Grid>
      <Box sx={{ mt: 4, width:'100%' }}>
              <TableThree highlighting={highlighting}
          dateRange={dateRange}
          currentArea={currentArea}
          selectedEmployee={selectedEmployee}  />
        </Box>
        </Box>
  );
}

export default Employees;
