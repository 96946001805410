import { Box, Typography } from "@mui/material";
import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

interface DateRangePickerComponentProps {
  dateRange: [Date | undefined, Date | undefined];
  setDateRange: (dateRange: [Date | undefined, Date | undefined]) => void;
}

const DateRangePickerComponent: React.FC<DateRangePickerComponentProps> = ({ dateRange, setDateRange }) => {
  
  const [startDate, endDate ] = dateRange

  // Helper function to reset time to midnight UTC
  const setToMidnightUTC = (date: Date | null): Date | undefined => {
    if (!date) return undefined;
    return new Date(Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), 0, 0, 0, 0));
  };

  return (
    <Box display="flex" justifyContent="center">

      <Typography
        fontSize={"18px"}
        sx={{ marginRight: "5px", paddingLeft: "8px" }}
      >
        Date Range:
      </Typography>
      <DatePicker
        selectsRange={true}
        startDate={startDate}
        endDate={endDate}
        onChange={(update: [Date | null, Date | null] | null) => {
          if (update) {
            const [start, end] = update;
            setDateRange([setToMidnightUTC(start), setToMidnightUTC(end)]);
          } else {
            setDateRange([undefined, undefined]);
          }
        }}
        isClearable={true}
      />
    </Box>
  );
};

export default DateRangePickerComponent;
